const { stage } = require('../../utils/stages');

const BASE_URLS = {
    dev: 'http://dev-profile-service.web.s7.ru',
    production: 'https://profile-service.web.s7.ru',
    qa: 'https://qa-service.s7.ru/ps3',
    stage: 'http://profile-service.web.s7.ru',
};

const HERMES_URLS = {
    dev: 'https://qa-service.s7.ru/hermes',
    production: 'http://informer-service.web.s7.ru/api',
    qa: 'https://qa-service.s7.ru/hermes',
    stage: 'http://informer-service.web.s7.ru/api',
};

const PASSBOOK_URLS = {
    dev: 'https://qa-service.s7.ru/passbook',
    production: 'https://passbook-service.web.s7.ru/passbook',
    qa: 'https://qa-service.s7.ru/passbook',
    stage: 'http://passbook-service.web.s7.ru/passbook',
};

const UNICORN_URLS = {
    dev: 'https://api.marketplace.s7.ru/api/platform/',
    production: 'https://api.marketplace.s7.ru/api/platform/',
    qa: 'https://api.marketplace.s7.ru/api/platform/',
    stage: 'https://api.marketplace.s7.ru/api/platform/',
};

const MBE_URLS = {
    dev: 'https://dev-mbe.api.s7.ru',
    production: 'https://mbe.api.s7.ru',
    qa: 'https://qa-mbe.api.s7.ru',
    stage: 'https://mbe.api.s7.ru',
};

const CONVERSATION_URLS = {
    dev: 'http://dev-mobile-proxy.web.s7.ru/mobile-proxy/api/v1/conversations',
    production:
        'https://mobile-proxy.web.s7.ru/mobile-proxy/api/v1/conversations',
    qa: 'https://qa-api.s7airlines.com/3.0/conversations',
    stage: 'https://mobile-proxy.web.s7.ru/mobile-proxy/api/v1/conversations',
};

const LANGUAGE_URLS = {
    dev: 'http://dev-cabin.web.s7.ru',
    production: 'https://stage-cabin.web.s7.ru',
    qa: 'http://qa-cabin.web.s7.ru',
    stage: 'http://stage-cabin.web.s7.ru',
};

const MEDIA_URLS = {
    dev: 'https://dev-s7media.s7.ru/',
    production: 'https://media.s7.ru/',
    qa: 'https://qa-s7media.s7.ru/',
    stage: 'https://stage-s7media.s7.ru/',
};

const MEDIA_RESIZE_URLS = {
    dev: 'https://qa-s7media.s7.ru/',
    production: 'https://media.s7.ru/',
    qa: 'https://qa-s7media.s7.ru/',
    stage: 'https://stage-s7media.s7.ru/',
};

const BASE_URL = BASE_URLS[stage];
const HERMES_URL = HERMES_URLS[stage];
const PASSBOOK_URL = PASSBOOK_URLS[stage];
const MBE_URL = MBE_URLS[stage];
const CONVERSATION_URL = CONVERSATION_URLS[stage];
const UNICORN_URL = UNICORN_URLS[stage];
const LANGUAGE_URL = LANGUAGE_URLS[stage];
const MEDIA_URL = MEDIA_URLS[stage];
const MEDIA_RESIZE_URL = MEDIA_RESIZE_URLS[stage];

const DOCSCAN_URL = 'https://api.regulaforensics.com/webapi';
const DOCSCAN_TRANSACTION_URL = `${DOCSCAN_URL}/Transaction2`;

const FFP_PKPASS_URL = `${PASSBOOK_URL}/external/ffp/`;
const FFP_JWT_URL = `${PASSBOOK_URL}/api/passes/google/createPass?passTypeIdentifier=pass.S7.ffp-card&cardNumber=`;

module.exports = {
    BASE_URL,
    CONVERSATION_URL,
    DOCSCAN_TRANSACTION_URL,
    DOCSCAN_URL,
    FFP_JWT_URL,
    FFP_PKPASS_URL,
    HERMES_URL,
    LANGUAGE_URL,
    MBE_URL,
    MEDIA_URL,
    MEDIA_RESIZE_URL,
    PASSBOOK_URL,
    UNICORN_URL,
};
